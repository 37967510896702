import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from 'components/Layout'
import {
  TopTitle,
  ImageContainer,
  SectionProductLineUp,
  ProductTitle,
  ProductContainer,
  ShowcaseContainer,
  ShowcaseTitle,
  ShowcaseTextContainer,
  FlexCenter,
  FlexCenterPerfectAccent,
  ShowcaseRightContainer,
  ShowcaseLeftContainer,
  ShowcaseContainerPerfectAccent,
  ShowcaseImageNew,
  CareerSection,
  ContentContainer,
  CareerImage,
  CareerTextContainer,
  FlexContainer,
  FlexColumn,
  AppLinksContainer,
  StoreIconContainer,
  AppStoreText,
} from 'components/HomeComponents'
import ModalOffer from 'components/ModalOffer'
import Seo from 'components/Seo'
import Textbox from 'components/common/Textbox'
import SocialSideBar from 'components/SocialSideBar'
import Subscriptions from 'components/Subscriptions'
import SubscriptionPlans from 'components/Subscriptions/SubscriptionPlans'
import {
  FLUENTWORLDS_GOOGLE_PLAY_STORE_DOWNLOAD_LINK,
  FLUENTWORLDS_APPLE_APP_STORE_DOWNLOAD_LINK,
  FLUENTWORLDS_ACADEMY_LINK,
  PERFECTACCENT_GOOGLE_PLAY_STORE_DOWNLOAD_LINK,
  PERFECTACCENT_APPLE_APP_STORE_DOWNLOAD_LINK,
} from 'constants/product-links'
import AppLoginHandler from 'components/AppLoginHandler'
import UserContext from 'context/user/UserContext'
import Subscribe from 'pages/blog/newsletter/Subscribe'

const Index = () => {
  const { t } = useTranslation('index')

  const { showDiscount, toggleShowDiscount } = useContext(UserContext)

  return (
    <div>
      <Seo title={t('title')} description={t('description')} />
      <AppLoginHandler />
      <Layout>
        <main>
          {showDiscount ? (
            <ModalOffer setShowDiscount={toggleShowDiscount} />
          ) : null}

          <div>
            <SocialSideBar />
            <ImageContainer>
              <TopTitle>
                <h1>{t('experienceTheJoy')}</h1>
                <h3>{t('immersive')}</h3>
              </TopTitle>
              <Subscribe />
            </ImageContainer>
          </div>
          <SectionProductLineUp>
            <ProductTitle>
              <h1>
                {t('ourSuite')}. {t('sweet')}.
              </h1>
            </ProductTitle>
            <ProductContainer>
              <a href="#fluentworlds">
                <StaticImage
                  src="../images/png/lineup_fw1.png"
                  alt="FluentWorlds"
                />
              </a>
              <h2>FluentWorlds</h2>
              <h4>{t('theMostExciting')}</h4>
              <AppLinksContainer>
                <StoreIconContainer>
                  <a
                    href={FLUENTWORLDS_GOOGLE_PLAY_STORE_DOWNLOAD_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      src="../images/png/google_play.png"
                      alt="google play"
                      height={30}
                    />
                  </a>
                  <AppStoreText>
                    <a
                      href={FLUENTWORLDS_GOOGLE_PLAY_STORE_DOWNLOAD_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Google Play
                    </a>
                  </AppStoreText>
                </StoreIconContainer>
                <StoreIconContainer>
                  <a
                    href={FLUENTWORLDS_APPLE_APP_STORE_DOWNLOAD_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      src="../images/png/apple.png"
                      alt="apple store"
                      height={30}
                    />
                  </a>

                  <AppStoreText>
                    <a
                      href={FLUENTWORLDS_APPLE_APP_STORE_DOWNLOAD_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      App Store
                    </a>
                  </AppStoreText>
                </StoreIconContainer>
              </AppLinksContainer>
            </ProductContainer>
            <ProductContainer>
              <a href="#academy">
                <StaticImage
                  src="../images/jpg/lineup_fwa2.jpg"
                  alt="FluentWorlds Academy"
                />
              </a>
              <h2>
                {t('fluentWorldsAcademy')}
                <br />
                3DMeet
              </h2>
              <h4>{t('personalTutoring')}</h4>
              <AppLinksContainer>
                <AppStoreText>
                  <a
                    href={FLUENTWORLDS_ACADEMY_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t('goToTheAcademy')}
                  </a>
                </AppStoreText>
              </AppLinksContainer>
            </ProductContainer>
            <ProductContainer>
              <a href="#perfectaccent">
                <StaticImage
                  src="../images/jpg/lineup_pa3.jpg"
                  alt="Perfect Accent"
                />
              </a>
              <h2>{t('perfectAccent')}</h2>
              <h4>{t('preciseGuidance')}</h4>
              <AppLinksContainer>
                <StoreIconContainer>
                  <a
                    href={PERFECTACCENT_GOOGLE_PLAY_STORE_DOWNLOAD_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      src="../images/png/google_play.png"
                      alt="google play"
                      height={30}
                    />
                  </a>
                  <AppStoreText>
                    <a
                      href={PERFECTACCENT_GOOGLE_PLAY_STORE_DOWNLOAD_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Google Play
                    </a>
                  </AppStoreText>
                </StoreIconContainer>
                <StoreIconContainer>
                  <a
                    href={PERFECTACCENT_APPLE_APP_STORE_DOWNLOAD_LINK}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <StaticImage
                      src="../images/png/apple.png"
                      alt="apple store"
                    />
                  </a>

                  <AppStoreText>
                    <a
                      href={PERFECTACCENT_APPLE_APP_STORE_DOWNLOAD_LINK}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      App Store
                    </a>
                  </AppStoreText>
                </StoreIconContainer>
              </AppLinksContainer>
            </ProductContainer>
          </SectionProductLineUp>

          <ShowcaseContainer style={{ backgroundColor: 'white' }}>
            <ShowcaseTitle name="fluentworlds">
              <h1>{t('theWorldIsNotFlat')}</h1>
              <h2>{t('languageLearning')}</h2>
            </ShowcaseTitle>
            <FlexCenter>
              <ShowcaseImageNew>
                <StaticImage
                  src="../images/png/start_fwshowcase.png"
                  alt="Fluent Worlds"
                />
              </ShowcaseImageNew>
              <ShowcaseLeftContainer>
                <h2>{t('whyFluentWorldsHeader')}</h2>
                <p>
                  {t('whyFluentWorldsParagraph')}
                  <span>
                    <Link to="/products#fluentworlds">{t('learnMore')}</Link>
                  </span>{' '}
                </p>
              </ShowcaseLeftContainer>
            </FlexCenter>
          </ShowcaseContainer>

          <ShowcaseContainer>
            <ShowcaseTitle name="academy">
              <h1>{t('lifeIsNotTwoDimensional')}</h1>
              <h2>{t('webConferencing')}</h2>
            </ShowcaseTitle>
            <StaticImage
              src="../images/jpg/start_fwashowcase.jpg"
              alt="FluentWorlds Academy"
            />
            <ShowcaseTextContainer>
              <h2>{t('fluentWorldsAcademyAnd3Dmeet')}</h2>
              <p>
                {t('learningAndConferencing')}
                <span>
                  <Link to="/products#academy">{t('learnMore')}</Link>
                </span>{' '}
              </p>
            </ShowcaseTextContainer>
          </ShowcaseContainer>

          <ShowcaseContainerPerfectAccent>
            <ShowcaseTitle name="perfectaccent">
              <h1>{t('howEffective')}</h1>
              <h2>{t('ourTechnology')}</h2>
            </ShowcaseTitle>
            <FlexCenterPerfectAccent>
              <ShowcaseImageNew>
                <StaticImage
                  src="../images/jpg/start_pashowcase.jpg"
                  alt="Perfect Accent"
                />
              </ShowcaseImageNew>
              <ShowcaseRightContainer>
                <h2>{t('whyPerfectAccentHeader')}</h2>
                <p>
                  {t('whyPerfectAccentParagraph')}
                  <span>
                    <Link to="/products#perfectaccent">{t('learnMore')}</Link>
                  </span>{' '}
                </p>
              </ShowcaseRightContainer>
            </FlexCenterPerfectAccent>
          </ShowcaseContainerPerfectAccent>

          <CareerSection>
            <ShowcaseTitle>
              <h1>{t('openTheDoor')}</h1>
              <h2>{t('toYourFuture')}</h2>
            </ShowcaseTitle>
            <ContentContainer>
              <CareerImage>
                <StaticImage
                  src="../images/jpg/start_fwcafe.jpg"
                  alt="career"
                />
              </CareerImage>
              <CareerTextContainer>
                <Textbox>
                  <FlexContainer>
                    <FlexColumn>
                      <h1>1</h1>
                      <p>{t('incomeIncrease')}</p>
                    </FlexColumn>
                    <FlexColumn>
                      <h1>2</h1>
                      <p>{t('masterInContext')}</p>
                    </FlexColumn>
                    <FlexColumn>
                      <h1>3</h1>
                      <p>{t('languageOfInternet')}</p>
                    </FlexColumn>
                    <FlexColumn>
                      <h1>4</h1>
                      <p>{t('jobOpportunities')}</p>
                    </FlexColumn>
                  </FlexContainer>
                </Textbox>
              </CareerTextContainer>
            </ContentContainer>
          </CareerSection>

          <SubscriptionPlans style={{ background: 'white' }}>
            <h1>{t('ourPlans')}</h1>
            <h4>{t('chooseSubscription')}</h4>
            <Subscriptions />
          </SubscriptionPlans>
        </main>
      </Layout>
    </div>
  )
}

export default Index
