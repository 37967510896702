import { useEffect } from 'react'
import { useQueryParam, StringParam } from 'use-query-params'

import { useAuth0 } from 'utils/react-auth0-spa'

// TODO: Remove this when FW has been updated to support the app-action system
const AppLoginHandler = () => {
  const redirectToApp = (app, token) => {
    if (typeof window !== 'undefined') {
      const protocol =
        app === 'fluentworlds'
          ? process.env.GATSBY_FW_APP_PROTOCOL
          : process.env.GATSBY_PA_APP_PROTOCOL
      if (!protocol || typeof protocol === 'undefined' || protocol === '') {
        console.warn('Invalid app passed [%s]', app)
        return
      }
      if (!token || typeof token === 'undefined' || token === '') {
        console.warn('Invalid token passed [%s]', token)
        return
      }
      const appRedirectURL = `${protocol}://login?token=${token}`
      window.location.replace(appRedirectURL)
    }
  }

  const {
    isAuthenticated,
    loginWithRedirect,
    getTokenSilently,
    auth0Loading,
  } = useAuth0()
  const [app] = useQueryParam('app', StringParam)
  const [platform] = useQueryParam('platform', StringParam)
  const [action] = useQueryParam('action', StringParam)

  const loginRedirectParams = `/account/?platform=${platform}&app=${app}`
  useEffect(() => {
    const shouldReturnToApp = () => {
      return (
        action !== 'manage' &&
        app &&
        typeof app !== 'undefined' &&
        platform &&
        typeof platform !== 'undefined'
      )
    }

    const sendTokenToApp = async () => {
      const token = await getTokenSilently()
      redirectToApp(app, token)
      return token
    }
    if (shouldReturnToApp() && !auth0Loading) {
      if (isAuthenticated) {
        sendTokenToApp()
      } else {
        loginWithRedirect({
          redirect_uri: process.env.GATSBY_WEBSITE_URL + loginRedirectParams,
        })
      }
    }
  }, [
    isAuthenticated,
    auth0Loading,
    app,
    platform,
    getTokenSilently,
    loginWithRedirect,
    loginRedirectParams,
    action,
  ])

  return null
}

export default AppLoginHandler
